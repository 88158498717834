import React from "react"

import { MainLayout } from "@app/layouts"
import { Icon } from "@app/components"

export const ContactPage = () => {
	return (
		<MainLayout page="contact">
			<div className="max-w-lg mx-auto my-6 px-4">
				<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
					<div className="sm:col-span-2">
						<dt className="font-medium text-gray-600">Mailing address</dt>
						<dd className="mt-1">
							Collaboration Wine Exports LLC
							<br />
							3723 Mosswood Dr
							<br />
							Lafayette, CA 94549-3511
							<br />
							USA
						</dd>
					</div>
					<div className="sm:col-span-1">
						<dt className="font-medium text-gray-600">Sales inquiries</dt>
						<dd className="mt-1">
							<a className="underline" href="mailto:sales@collaboration.wine">
								sales@collaboration.wine
							</a>
						</dd>
					</div>
					<div className="sm:col-span-1">
						<dt className="font-medium text-gray-600">Billing inquiries</dt>
						<dd className="mt-1">
							<a className="underline" href="mailto:billing@collaboration.wine">
								billing@collaboration.wine
							</a>
						</dd>
					</div>
					<div className="sm:col-span-2 text-center py-2 space-x-4">
						<div className="inline-block">
							<a href="weixin://dl/chat?wxid_jsoahtjr5x3e12">
								<Icon name="WeChat" size="2x" />
								<div className="text-gray-900 text-sm">WeChat</div>
							</a>
						</div>
						<div className="inline-block">
							<a href="https://www.instagram.com/collabwinex">
								<Icon name="Instagram" size="2x" />
								<div className="text-gray-900 text-sm">Instagram</div>
							</a>
						</div>
						<div className="inline-block">
							<a href="https://twitter.com/collabwinex">
								<Icon name="Twitter" size="2x" />
								<div className="text-gray-900 text-sm">Twitter</div>
							</a>
						</div>
					</div>
				</dl>
			</div>
		</MainLayout>
	)
}
