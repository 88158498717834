import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"
import { createGlobalState } from "react-hooks-global-state"

import { slugify, any } from "@app/util"
import { useSession } from "@app/contexts"

const initialState = {
	avas: [],
	basicPages: {},
	basket: {
		products: [],
	},
	brands: [],
	caseFormats: [],
	certifications: {},
	crafted: [],
	priceRange: {
		min: 0,
		max: 0,
	},
	enjoying: [],
	products: [],
	promotions: [],
	rating: [],
	showLogin: false,
	snippets: {},
	varietals: [],
	vintages: [],
	filters: [],
}

const ratingOrdering = [
	"95+ points",
	"90-94 points",
	"best in class",
	"double gold",
	"gold",
	"silver",
	"bronze",
	"top 100 wine enthusiast",
	"top 100 wine spectator",
	"editors' choice",
	"best buy",
]

export const { useGlobalState } = createGlobalState(initialState)

export const State = () => {
	const { pathname } = useLocation()
	const { authToken, setAuthToken, rpc, loggedIn } = useSession()

	const [, setAVAs] = useGlobalState("avas")
	const [, setBrands] = useGlobalState("brands")
	const [, setCaseFormats] = useGlobalState("caseFormats")
	const [, setCertifications] = useGlobalState("certifications")
	const [, setCrafted] = useGlobalState("crafted")
	const [, setEnjoying] = useGlobalState("enjoying")
	const [, setFilters] = useGlobalState("filters")
	const [, setPriceRange] = useGlobalState("priceRange")
	const [, setPromotions] = useGlobalState("promotions")
	const [, setRating] = useGlobalState("rating")
	const [, setVarietals] = useGlobalState("varietals")
	const [, setVintages] = useGlobalState("vintages")
	const [products, setProducts] = useGlobalState("products")

	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		const load = async () => {
			setLoading(true)
			let result
			const injected = _.get(window, "__GetCatalog")
			if (_.isEmpty(authToken) && !_.isNil(injected)) {
				result = injected
			} else {
				result = await rpc("GetCatalog")
			}
			const authenticated = _.get(result, "authenticated", false)
			if (!authenticated) {
				setAuthToken(undefined)
			}
			const avaMap = {}
			let brandMap = {}
			const caseFormatMap = {}
			const certificationsMap = {}
			const craftedMap = {}
			const enjoyingMap = {}
			const ratingMap = {}
			const promotionsMap = {}
			const varietalMap = {}
			const vintageMap = {}
			const priceRange = { min: Number.MAX_SAFE_INTEGER, max: 0 }

			const products = _.map(result.products, (product) => {
				priceRange.min = _.isNumber(product.price)
					? Math.min(priceRange.min, product.price)
					: priceRange.min
				priceRange.max = _.isNumber(product.price)
					? Math.max(priceRange.max, product.price)
					: priceRange.max

				const { shopEnabled } = product

				const avaName = _.get(result.avas, `${product.avaID}.name`, "")
				const avaGroup = _.get(result.avas, `${product.avaID}.group`, "")
				const avaSlug = slugify(avaName)
				if (!avaMap[product.avaID]) {
					avaMap[product.avaID] = {
						group: avaGroup,
						count: 1,
						name: avaName,
						slug: avaSlug,
						sortBy: _.toLower(avaName),
						shopEnabled,
					}
				} else {
					avaMap[product.avaID].count += 1
					avaMap[product.avaID].shopEnabled ||= shopEnabled
				}

				const brandName = _.get(result.brands, `${product.brandID}.name`, "")
				const brandSlug = slugify(brandName)
				const brandShippingPoint = _.get(result.brands, `${product.brandID}.shippingPoint[0]`, "")
				if (!brandMap[product.brandID]) {
					brandMap[product.brandID] = {
						name: brandName,
						sortBy: _.toLower(brandName),
						slug: brandSlug,
						count: 1,
						shopEnabled,
					}
				} else {
					brandMap[product.brandID].count += 1
					brandMap[product.brandID].shopEnabled ||= shopEnabled
				}

				const caseFormat = _.get(result.caseFormats, `${product.caseFormatID}.name`, "")
				const caseFormatSlug = slugify(caseFormat)
				if (!caseFormatMap[product.caseFormatID]) {
					caseFormatMap[product.caseFormatID] = {
						name: caseFormat,
						sortBy: _.toLower(caseFormat),
						slug: caseFormatSlug,
						count: 1,
						shopEnabled,
					}
				} else {
					caseFormatMap[product.caseFormatID].count += 1
					caseFormatMap[product.caseFormatID].shopEnabled ||= shopEnabled
				}

				const certifications = _.chain(product.certifications)
					.map((name) => {
						const slug = slugify(name)
						if (!certificationsMap[name]) {
							certificationsMap[name] = {
								name: name,
								sortBy: _.toLower(name),
								slug: slug,
								count: 1,
								shopEnabled,
							}
						} else {
							certificationsMap[name].count += 1
							certificationsMap[name].shopEnabled ||= shopEnabled
						}
						return { name, slug }
					})
					.sortBy("name")
					.value()

				const crafted = _.chain(product.crafted)
					.map((name) => {
						const slug = slugify(name)
						if (!craftedMap[name]) {
							craftedMap[name] = {
								name: name,
								sortBy: _.toLower(name),
								slug: slug,
								count: 1,
								shopEnabled,
							}
						} else {
							craftedMap[name].count += 1
							craftedMap[name].shopEnabled ||= shopEnabled
						}
						return { name, slug }
					})
					.sortBy("name")
					.value()

				const enjoying = _.chain(product.enjoying)
					.map((name) => {
						const slug = slugify(name)
						if (!enjoyingMap[name]) {
							enjoyingMap[name] = {
								name: name,
								sortBy: _.toLower(name),
								slug: slug,
								count: 1,
								shopEnabled,
							}
						} else {
							enjoyingMap[name].count += 1
							enjoyingMap[name].shopEnabled ||= shopEnabled
						}
						return { name, slug }
					})
					.sortBy("name")
					.value()

				const promotions = _.chain(product.promotions)
					.map((name) => {
						const slug = slugify(name)
						const hide = name === "CollabWineX Select"
						if (!promotionsMap[name]) {
							promotionsMap[name] = {
								name: name,
								sortBy: _.toLower(name),
								slug: slug,
								count: 1,
								hide,
								shopEnabled,
							}
						} else {
							promotionsMap[name].count += 1
							promotionsMap[name].shopEnabled ||= shopEnabled
						}

						return { name, slug, hide }
					})
					.sortBy("name")
					.value()

				const hasNotes = any([product.aroma, product.taste, product.finish, product.notes])

				const rating = _.map(product.rating, (name) => {
					const slug = slugify(name)
					let sortBy = 0
					_.each(ratingOrdering, (ro, i) => {
						if (ro === _.toLower(name)) {
							sortBy = i
							return false
						}
					})
					if (!ratingMap[name]) {
						ratingMap[name] = {
							name: name,
							sortBy: sortBy,
							slug: slug,
							count: 1,
							shopEnabled,
						}
					} else {
						ratingMap[name].count += 1
						ratingMap[name].shopEnabled ||= shopEnabled
					}
					return { name, slug }
				})

				const vintage = (product.vintage || "NV").toString()
				const vintageSlug = slugify(vintage)
				if (!vintageMap[vintage]) {
					vintageMap[vintage] = {
						name: vintage,
						sortBy: vintage,
						slug: vintageSlug,
						count: 1,
						shopEnabled,
					}
				} else {
					vintageMap[vintage].count += 1
					vintageMap[vintage].shopEnabled ||= shopEnabled
				}

				const name = _.trim(`${product.name}`)
				const nameSlug = name.replace(/[^\w]+/g, "-")
				const sortBy = _.toLower(product.name)

				const varietals = _.map(product.varietals, (id) => {
					const name = _.get(result.varietals, `${id}.name`, "")
					const slug = slugify(name)
					if (!varietalMap[id]) {
						varietalMap[id] = {
							name: name,
							sortBy: _.toLower(name),
							slug: slug,
							count: 1,
							shopEnabled,
						}
					} else {
						varietalMap[id].count += 1
						varietalMap[id].shopEnabled ||= shopEnabled
					}
					return { name, slug }
				})

				let website = _.trim(_.toLower(product.website))
				if (!_.isEmpty(website) && !_.startsWith(website, "http")) {
					website = `http://${website}`
				}

				return {
					...product,
					avaName,
					avaSlug,
					avaGroup,
					brandName,
					brandSlug,
					shippingPoint: brandShippingPoint,
					caseFormat,
					caseFormatSlug,
					certifications,
					crafted,
					enjoying,
					hasNotes,
					name,
					nameSlug,
					promotions,
					rating,
					sortBy,
					varietals,
					vintage,
					vintageSlug,
					website,
					select: _.some(promotions, (promo) => promo.name === "CollabWineX Select")
						? "CollabWineX Select"
						: "",
					craftedNotes: _.map(crafted || [], "name").join(", "),
					enjoyingNotes: _.map(enjoying || [], "name").join(", "),
					promotionsNotes: _.compact(
						_.map(promotions || [], (promo) => (promo.hide ? null : promo.name)),
					).join(", "),
					ratingNotes: _.map(rating || [], "name").join(", "),
					varietalsNotes: _.map(varietals || [], "name").join(", "),
				}
			})

			_.each(result.brands, (b) => {
				if (brandMap[b.id]) {
					brandMap[b.id].description = b.description || ""
					brandMap[b.id].shippingPoint = b.shippingPoint || []
				}
			})

			const sorted = (arr, opts = {}) => {
				let options = _.chain(arr)
					.map((v) => {
						let slug
						if (v.name) {
							slug = slugify(v.name)
						}
						return { ...v, slug }
					})
					.sortBy("sortBy")
					.value()

				if (opts.reverse) {
					options = _.reverse(options)
				}

				return { map: arr, options, groups: _.groupBy(options, (v) => _.get(v, "group", "")) }
			}

			priceRange.min -= priceRange.min % 10
			priceRange.max += 10 - (priceRange.max % 10)

			const priceShortcuts = [priceRange.min, 80, 120, 180, 240, 360, 500]

			const priceShortcutOptions = _.reduce(
				priceShortcuts,
				(acc, price, i) => {
					if (i === 0) {
						return acc
					}
					const left = priceShortcuts[i - 1]
					const right = price
					if (i === _.size(priceShortcuts) - 1) {
						acc.push({
							name: `$ ${right}+`,
							sortBy: _.size(acc),
							slug: `${right}+${priceRange.max}`,
							count: 0,
						})
					} else {
						acc.push({
							name: `$ ${left} — $ ${right}`,
							sortBy: _.size(acc),
							slug: `${left}+${right}`,
							count: 0,
						})
					}
					return acc
				},
				[],
			)

			const state = {
				avas: sorted(avaMap),
				brands: sorted(brandMap),
				caseFormats: sorted(caseFormatMap),
				certifications: sorted(certificationsMap),
				crafted: sorted(craftedMap),
				enjoying: sorted(enjoyingMap),
				products: _.sortBy(products, "sortBy"),
				promotions: sorted(promotionsMap),
				priceRange: priceRange,
				priceShortcuts: {
					groups: {
						"": priceShortcutOptions,
					},
				},
				rating: sorted(ratingMap),
				varietals: sorted(varietalMap),
				vintages: sorted(vintageMap, { reverse: true }),
			}

			setAVAs(state.avas)
			setBrands(state.brands)
			setCaseFormats(state.caseFormats)
			setCertifications(state.certifications)
			setCrafted(state.crafted)
			setEnjoying(state.enjoying)
			setProducts(state.products)
			setPromotions(state.promotions)
			setPriceRange(state.priceRange)
			setRating(state.rating)
			setVarietals(state.varietals)
			setVintages(state.vintages)

			setFilters(() =>
				_.chain([
					{ name: "AVA", key: "ava", slug: "avaSlug", ...state.avas },
					{
						name: "Wineries",
						key: "winery",
						slug: "brandSlug",
						...state.brands,
					},
					{
						name: "Case format",
						key: "format",
						slug: "caseFormatSlug",
						hidden: true,
						...state.caseFormats,
					},
					{
						name: "Certificates",
						key: "certifications",
						slug: "certifications",
						hidden: true,
						...state.certifications,
					},
					{
						name: "Crafted",
						key: "crafted",
						slug: "crafted",
						hidden: true,
						...state.crafted,
					},
					{
						name: "Enjoying",
						key: "enjoying",
						slug: "enjoying",
						hidden: true,
						...state.enjoying,
					},
					{
						name: "Promotions",
						key: "promotions",
						slug: "promotions",
						...state.promotions,
					},
					{
						name: "Case Price",
						key: "price",
						slug: "price",
						mutuallyExclusive: true,
						range: state.priceRange,
						...state.priceShortcuts,
					},
					{
						name: "Rating",
						key: "rating",
						slug: "rating",
						...state.rating,
					},
					{
						name: "Varietal",
						key: "varietal",
						slug: "varietals",
						...state.varietals,
					},
					{
						name: "Vintage",
						key: "vintage",
						slug: "vintageSlug",
						...state.vintages,
					},
				])
					.filter(
						(filter) => !_.isEmpty(filter.options) || (!_.isEmpty(filter.range) && loggedIn()),
					)
					.sortBy("name")
					.value(),
			)

			setLoading(false)
		}

		const segments = pathname.split("/")
		const loggingIn = _.size(segments) === 3 && segments[1] === "login" && !_.isEmpty(segments[2])

		if (_.size(products) === 0) {
			if (!loggingIn && pathname !== "/" && !loading) {
				load()
			}
		}
	}, [authToken, pathname])

	return null
}
